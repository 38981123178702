<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
    
    <div class="fullscreen-bg videoWrapper" v-if="$vuetify.breakpoint.mdAndUp">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2FLogistics.mp4?alt=media&token=0bc31624-bdbe-4447-b70b-2c577fd5ebea" type="video/mp4">
      </video>
      <div class="overlay-clear">
        <v-container>
          <div class="display-3">
            <span class="thin">Logistical Solutions With</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Intelligent Data</span>
          </div>
        </v-container>
      </div>
    </div>

    <div class="fullscreen-bg videoWrapper" v-if="$vuetify.breakpoint.smAndDown">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2FLogisticsSmall2.mp4?alt=media&token=2b7f36a7-df1d-4098-94d6-3628b7ddc060" type="video/mp4">
      </video>
      <div class="overlay-clear">
        <v-container>
          <div class="display-3">
            <span class="thin">Logistical Solutions With</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Intelligent Data</span>
          </div>
        </v-container>
      </div>
    </div>
	  
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Innovative inventory tracking in real time</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Application interface</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Cost savings</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Provides consistent methods and measurements</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Eliminates product loss</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Logistics',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>